import React from 'react'

import { t } from '../i18n'
import { NormalizedMetatag, DrupalNode } from '../drupal'
import { dimensions } from '../style/variables'
import Layout from '../layouts'
import Container from '../components/Container'
import Banner from '../components/Banner'

interface Props {
	data: {
		banner: {
			childImageSharp: {}
		}
	}
}

// Add noindex metatag to 404 page.
// https://support.google.com/webmasters/answer/93710?hl=en
const Metatags: NormalizedMetatag[] = [{ tag: 'meta', attributes: { name: 'robots', content: 'noindex' } }]

const IndexPage: React.FC<Props> = () => (
	<Layout node={{ title: '404', metatag_normalized: Metatags } as DrupalNode}>
		<Banner />
		<Container style={{ textAlign: 'center', marginTop: `${dimensions.containerPadding}rem` }}>
			<h1>404 😢</h1>
			<p>{t('The requested URL was not found')}</p>
		</Container>
	</Layout>
)

export default IndexPage
